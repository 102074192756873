<template>
  <section fluid>
    <p class="text-h5 secondary--text">
      Liquidación de contratos:
      {{ seguimientoContrato?.contrato?.nombre_contrato }}
    </p>
    <v-row v-if="idOrdenCompra">
      <v-col cols="12" md="5" lg="3">
        <div class="sticky">
          <indice
            :current-target="currentTarget"
            @goTo="goTo($event)"
            :hideSancionesMultas="handleSancionesMultasVisibility"
          />
        </div>
      </v-col>
      <v-col cols="12" md="7" lg="9">
        <datos-generales id="datosGenerales" v-intersect="onIntersect" />
        <descripcion-obs id="descripcionObs" v-intersect="onIntersect" />
        <documentos-contrato id="documentosProceso" v-intersect="onIntersect" />
        <etapas-contrato id="etapasContrato" v-intersect="onIntersect" />
        <historico-pagos id="historicoPagos" v-intersect="onIntersect" />
        <!-- <v-card>
          <p class="text-h6 secondary--text ma-4 pt-4">Sanciones</p>

          <sanciones
            v-if="handleSancionesMultasVisibility"
            id="sanciones"
            v-intersect="onIntersect"
            hideButton
          />
        </v-card> -->

        <!-- <multas
          v-if="handleSancionesMultasVisibility"
          id="multas"
          v-intersect="onIntersect"
          hideButton
        /> -->
        <!-- <multas id="multas" v-intersect="onIntersect" :allowEdit="false"/> -->
        <observaciones id="observaciones" v-intersect="onIntersect" />
        <acta-liquidacion
          ref="liquidacion"
          :onlyView="$route.params?.onlyView ?? 0"
          :contrato="seguimientoContrato"
          @acta-guardada="getContrato"
        />
      </v-col>
    </v-row>
    <v-row class="my-4 ml-2">
      <v-btn color="secondary" outlined @click="validarRouterBack"
        >Volver</v-btn
      >
      <v-btn
        v-if="permitirLiquidacion"
        color="secondary"
        class="mx-4"
        :loading="loading"
        @click="liquidarModal = true"
        >Liquidar</v-btn
      >
    </v-row>
    <dialogoConfirmacion
      :show="liquidarModal"
      title="¿Desea continuar con la etapa de liquidación de orden de compra?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="liquidarModal = false"
      @confirm="liquidarOrden()"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import DatosGenerales from "./components/liquidarOrden/DatosGeneralesComponent.vue";
import DescripcionObs from "./components/liquidarOrden/DescripcionObsComponent.vue";
import DocumentosContrato from "./components/liquidarOrden/DocumentosContratoComponent.vue";
import EtapasContrato from "./components/liquidarOrden/EtapasContratoComponent.vue";
import HistoricoPagos from "./components/liquidarOrden/HistoricoPagoComponent.vue";
import Sanciones from "./components/liquidarOrden/SancionesComponent.vue";
import Multas from "./components/liquidarOrden/MultasComponent.vue";
import Observaciones from "./components/liquidarOrden/ObservacionesComponent.vue";
import ActaLiquidacion from "./components/liquidarOrden/ActaLiquidacionComponent.vue";
import Indice from "./components/liquidarOrden/IndiceComponent.vue";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "ordenCompraLiquidarOrdenView",
  components: {
    DatosGenerales,
    DescripcionObs,
    DocumentosContrato,
    EtapasContrato,
    HistoricoPagos,
    Sanciones,
    Multas,
    Observaciones,
    ActaLiquidacion,
    Indice,
    dialogoConfirmacion,
  },
  data: () => ({
    currentTarget: "#datosGenerales",
    liquidarModal: false,
    loading: false,
  }),
  computed: {
    ...mapState("seguimientoOrden", [
      "seguimientoContrato",
      "liquidacion_compra",
      "montos",
    ]),
    ...mapState("procesoCompra", ["idOrdenCompra"]),
    ...mapState("agregarInsumo", ["id_solicitud"]),
    handleSancionesMultasVisibility() {
      if (this.seguimientoContrato) {
        const { contrato } = this.seguimientoContrato;
        return contrato?.id_forma_contratacion !== 6;
      } else return false;
    },
    permitirLiquidacion() {
      return ( (+this.montos.monto_restante > 0 ||  +this.montos.monto_restante === 0) && this.seguimientoContrato?.contrato?.id_estado_contrato !== 3 && this.seguimientoContrato?.acta_liquidacion );
    },
  },
  methods: {
    ...mapActions("seguimientoOrden", ["getContrato"]),
    ...mapMutations("procesoCompra", ["setIdOrdenCompra", "getEtapaProgreso"]),
    ...mapMutations("agregarInsumo", ["setCurrentProvider"]),
    goTo(target) {
      const options = {
        duration: 500,
        offset: 10,
        easing: "easeInOutCubic",
      };
      this.$vuetify.goTo(target, options);
      this.currentTarget = target;
    },
    onIntersect(entries, observer) {
      if (entries[0].isIntersecting) {
        this.currentTarget = `#${entries[0].target.id}`;
      }
    },
    async liquidarOrden() {
      this.loading = true;
      let formData = new FormData();

      Object.keys(this.liquidacion_compra).forEach((key) => {
        formData.append(key, this.liquidacion_compra[key]);
      });
      
      const { status } =
        await this.services.ContratoService.postContratoLiquidacion(
          this.$route.params.idContrato,
          formData
        ).finally( () => {
          this.loading = false;
        });

      if (status === 201) {
        this.liquidarModal = false;
        this.getEtapaProgreso(null);
        this.$router.push("/contratos-asignados");
      }

    },
    validarRouterBack() {
      if (this.$route.params?.onlyView == "1") {
        this.$router.back();
      }

      if (this.seguimientoContrato?.contrato?.id_estado_contrato === 3) {
        if (this.id_solicitud > 0) {
          this.$router.back();
        } else {
          this.$router.replace({ name: "contratos-asignados" });
        }
      } else {
        this.$router.back();
      }
    },
  },
  async created() {
    await this.getContrato(this.$route.params.idContrato);
    this.setIdOrdenCompra(this.$route.params.idContrato);
    this.setCurrentProvider(this.seguimientoContrato?.contrato?.id_referencia);
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 76px;
  z-index: 1;
}
</style>
