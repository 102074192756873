<template>
  <v-card class="pa-4 mb-6">
    <p class="text-h6 secondary--text">Datos Generales</p>
    <info-producto-component hideMount :showEyeButton="handleVisibility" />
    <!-- <perfil-proveedor />
    <estrellas-component :start="4" color="secondary" :size="40" /> -->
    <br />
    <p class="text-caption primary--text mb-0">Técnico asignado</p>
    <p class="text-subtitle-1 font-weight-bold primary--text mb-4">
      {{ seguimientoContrato?.contrato?.informacion_contrato?.tecnico_asignado?.primer_nombre }}
      {{ seguimientoContrato?.contrato?.informacion_contrato?.tecnico_asignado?.segundo_nombre }}
      {{ seguimientoContrato?.contrato?.informacion_contrato?.tecnico_asignado?.tercer_nombre }}
      {{ seguimientoContrato?.contrato?.informacion_contrato?.tecnico_asignado?.primer_apellido }}
      {{ seguimientoContrato?.contrato?.informacion_contrato?.tecnico_asignado?.segundo_apellido }}
      {{ seguimientoContrato?.contrato?.informacion_contrato?.tecnico_asignado?.apellido_casada }}
    </p>
    <template v-if="!hideAdminContrato">
      <p class="text-caption primary--text mb-0">Administrador de contrato</p>
      <p class="text-subtitle-1 font-weight-bold primary--text mb-4">
        {{ administradoresContrato(seguimientoContrato?.contrato.administradoresContrato) }}
      </p>
    </template>
    <p class="text-caption primary--text mb-0">Número de contrato</p>
    <p class="text-subtitle-1 font-weight-bold primary--text mb-4">
      {{ seguimientoContrato?.contrato?.numero }}
    </p>
    <p class="text-caption primary--text mb-0">Número de contrato interno</p>
    <p class="text-subtitle-1 font-weight-bold primary--text mb-4">
      {{ seguimientoContrato?.contrato?.numero_interno }}
    </p>
    <p class="text-caption primary--text mb-0">Fecha y hora de contratación</p>
    <p class="text-subtitle-1 font-weight-bold primary--text mb-4">
      {{
        moment(seguimientoContrato?.contrato?.fecha_hora_contratacion).format(
          "DD/MM/YYYY hh:mm"
        )
      }}
    </p>
    <p class="text-caption primary--text mb-0">Monto adjudicado</p>
    <p class="text-subtitle-1 font-weight-bold primary--text mb-4">
      ${{ seguimientoContrato?.contrato?.monto_adjudicado }}
    </p>
    <!-- <p class="text-caption primary--text mb-0">Justificación de la compra</p>
    <p class="text-subtitle-1 font-weight-bold primary--text mb-4">
      Se entregará en x instalación, ya que se comparte almacenamiento con x
      unidad...
    </p> -->
    <p class="text-caption primary--text mb-0">Contrato</p>
    <p class="text-subtitle-1 font-weight-bold primary--text mb-4">
      <v-icon @click="downloadDoc()" color="primary" :disabled="!getDocumentoContrato.ruta_archivo"
        >mdi-file-document-outline</v-icon
      >
      {{ validarDocumentoContrato }}
    </p>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import EstrellasComponent from "@/components/EstrellasComponent.vue";
import PerfilProveedor from "@/components/PerfilProveedorComponent.vue";
import infoProductoComponent from "@/components/InfoProductoComponent.vue";
export default {
  name: "DatosGeneralesComponent",
  components: { EstrellasComponent, PerfilProveedor, infoProductoComponent },
  computed: {
    ...mapState("seguimientoOrden", ["seguimientoContrato"]),
    handleVisibility() {
      if (this.seguimientoContrato) {
        const { contrato } = this.seguimientoContrato;
        return contrato?.id_forma_contratacion === 5;
      } else return false;
    },

    hideAdminContrato() {
      if (this.seguimientoContrato) {
        const { contrato } = this.seguimientoContrato;
        return (
          contrato?.id_forma_contratacion === 5 ||
          contrato?.id_forma_contratacion === 6
        );
      } else return false;
    },

    getDocumentoContrato() {
      if (
        this.seguimientoContrato &&
        this.seguimientoContrato.contrato.documentos.length > 0
      ) {
        return this.seguimientoContrato.contrato.documentos.find(
          (target) => target.id_tipo_documento_orden === 1
        );
      } else return {};
    },

    validarDocumentoContrato() {
      if (this.getDocumentoContrato.nombre_archivo) {
        return this.getDocumentoContrato.nombre_archivo; 
      } 
      if (this.getDocumentoContrato.ruta_archivo) {
        return "Contrato";
      }
      return "No se encontró ningún contrato";
    },
  },
  methods: {
    administradoresContrato(administradores) {
      return administradores.map((a) => `${a.nombres} ${a.apellidos}`).join(', ');
    },
    async downloadDoc() {
      const response = await this.services.ContratoService.getDocumentoContrato(
        this.getDocumentoContrato.id
      );
      // Descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${this.getDocumentoContrato.nombre_archivo ?? "Contrato"}.pdf`
      );
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
