<template>
  <v-card class="pa-4 mb-6">
    <p class="text-h6 secondary--text">
      Documentos del contrato
    </p>
    <v-col cols="12">
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in documentos" :key="i">
          <v-expansion-panel-header> {{ item.key }} </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              :headers="headersDocs"
              :items="item.value"
              no-data-text="No hay datos para mostrar"
              hide-default-footer
              :items-per-page="-1"
            >
            <template v-slot:[`item.recepcion`]="{ item }">
              {{ item.recepcion ? item.recepcion : 'No tiene registrada una recepción' }}
            </template>
              <template v-slot:[`item.nombre_archivo`]="{ item }">
                {{ nombreArchivo(item) }}
              </template>
              <template v-slot:[`item.fecha_carga`]="{ item }">
                {{ moment(item.fecha_emision).format("DD/MM/YYYY") }}
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="
                        descargarDocumentoManejador({
                          disk: disk(item?.tipo_contrato),
                          ruta_documento: item?.ruta_archivo,
                        })
                      "
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-file-download-outline</v-icon>
                    </v-btn>
                  </template>

                  <span>Descargar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DocumentosContratoComponent",
  components: { },
  data: () => ({
    documentos: [],
    headersDocs: [
      { text: "Documento", value: "nombre_archivo" },
      { text: "Fecha carga", align: "center", value: "fecha_carga" },
      { text: "Acciones", align: "center", value: "acciones", sortable: false },
    ],
  }),
  methods: {
    ...mapActions("procesoCompraDoc", ["descargarArchivoProceso"]),
    disk(tipo_contrato) {
      switch (tipo_contrato) {
        case "Contrato":
          return "contratos";
        case "Otros":
          return "contratos";
        case "Garantía":
          return "contratos";
        case "Informe del administrador de contrato":
          return "editarContrato";
        case "Documento de aceptación del contratista":
          return "editarContrato";
        case "Aprobación máxima autoridad":
          return "editarContrato";
        case "Documento de modificativa":
          return "editarContrato";
        case "Comprobante de pago":
          return "contratos";
        case "Condiciones de pago":
          return "contratos";
        case "Documentos forma de pago":
          return "contratos";
        default:
          return "local";
      }
    },
    nombreArchivo(item) {      
      return item.nombre_archivo
        ? item.nombre_archivo.split("-")[0].split("_").join(" ")
        : `Documento de ${item.tipo_contrato.toLowerCase()}`;
    },
    async descargarDocumentoManejador(carga, disk = null) {
      if (disk !== null) {
        carga.disk = disk;
      }
      try {
        await this.descargarArchivoProceso(carga, false);
      } catch (e) {        
        if ((this.segundo_error = false)) {
          this.segundo_error = true;
          await this.descargarDocumentoManejador(carga, "contratos");
        } else {
          this.segundo_error = false;
          this.pushAppMessage({
            show: true,
            message: "Error al descargar el archivo",
            type: "error",
          });
        }
      }
    },
    async getDocumentosContrato() {
      const { data } =
        await this.services.ContratoService.getDocumentosContrato(
          this.$route.params.idContrato
        );
        const documentos = [];

        Object.keys(data).forEach((key) => {
          documentos.push({
            key,
            value: data[key],
          });
        });
        this.documentos = documentos;
    },
  },
  created() {
    this.getDocumentosContrato();
  },
};
</script>
